<template>
  <div class="space-y-2">
    <h2 class="font-bold">Lidmaatschappen</h2>

    <div class="space-y-4">
      <ul class="space-y-3">
        <person-group
          v-for="group in groupedMemberships"
          :key="group.id"
          :group="group"
        />
      </ul>

      <person-new-membership
        :person-id="props.person.id"
        @reload:person="emit('reload:person')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  import PersonGroup from './PersonGroup.vue'
  import PersonNewMembership from './PersonNewMembership.vue'

  const emit = defineEmits(['reload:person'])
  const props = defineProps<{ person: Person }>()

  const groupedMemberships = computed(() => {
    const memberships = props.person.memberships as GroupMembership[]
    const groupIds = memberships.map((m) => m.group.id)
    const uniqIds = Array.from(new Set(groupIds))

    return uniqIds
      .flatMap((id) => {
        const membership = memberships.find((m) => m.group.id === id)

        if (!membership) {
          return []
        }

        return {
          id: membership.group.id,
          name: membership.group.name,
          memberships: memberships
            .filter((m) => m.group.id === membership.group.id)
            .map((m) => {
              return {
                endingAt: m.endingAt,
                id: m.id,
                role: m.role,
                startingAt: m.startingAt,
              }
            }),
        }
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1))
  })
</script>
