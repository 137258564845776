<template>
  <div class="space-y-2">
    <h1 class="flex items-center text-lg font-bold">
      <span class="truncate">Groepen</span>

      <div v-if="rootGroups.length" class="ml-auto flex gap-2">
        <router-link :to="{ name: 'NewGroup' }" class="button pl-2">
          <plus-icon class="w-4" />
          <span class="whitespace-nowrap">Nieuwe groep</span>
        </router-link>
      </div>
    </h1>

    <div class="grid gap-4 sm:grid-cols-7">
      <nav class="sm:col-span-2">
        <ul>
          <nested-groups
            v-for="group in rootGroups"
            :group="group"
            :groups="groups || []"
            :selected-group-id="selectedGroupId"
          />
        </ul>
      </nav>

      <router-view
        :groups="groups"
        @set:groupId="selectedGroupId = $event"
        @reload:user="emit('reload:user')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { inject, nextTick, ref, Ref } from 'vue'
  import { useHead } from '@vueuse/head'
  import { PlusIcon } from '@heroicons/vue/20/solid'

  import NestedGroups from '../components/NestedGroups.vue'
  import { useRouter } from 'vue-router'

  const emit = defineEmits(['reload:user'])
  const groups = inject<Ref<Group[]>>('authorizedGroups')
  const router = useRouter()

  const rootGroups = groups?.value.filter((g) => g.parentId === null) || []
  const selectedGroupId = ref<string | undefined>(undefined)

  const setInitialGroup = () => {
    if (groups && groups.value && !selectedGroupId.value) {
      const firstGroup = groups.value[0]

      if (firstGroup) {
        router.push({
          name: 'GroupDetails',
          params: { groupId: firstGroup.id },
        })
      }
    }
  }

  nextTick(setInitialGroup)
  useHead({ title: 'Groepen' })
</script>
