<template>
  <li class="space-y-1">
    <h3 class="text-sm font-semibold">{{ group.name }}</h3>

    <ul>
      <person-group-membership
        v-for="membership in sortedMemberships"
        :membership="membership"
        :key="membership.id"
      />
    </ul>
  </li>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import PersonGroupMembership from './PersonGroupMembership.vue'
  const props = defineProps<{ group: GroupMemberships }>()

  const sortedMemberships = computed(() => {
    return [...props.group.memberships].sort((a, b) => {
      return a.role.title > b.role.title ? 1 : -1
    })
  })
</script>
