<template>
  <div class="fixed bottom-3 right-3">
    <div
      @click="feedback.openDialog"
      class="cursor-pointer rounded-lg border bg-white px-4 py-2 shadow-md transition-all hover:bg-slate-200 hover:text-slate-600 hover:shadow-lg"
    >
      Support
    </div>
  </div>
</template>

<script setup lang="ts">
  import * as Sentry from '@sentry/vue'

  const feedback = Sentry.feedbackIntegration({
    autoInject: false,
    colorScheme: 'light',
    buttonLabel: 'Contact',
    cancelButtonLabel: 'Annuleren',
    emailLabel: 'Je e-mailadres',
    emailPlaceholder: '',
    formTitle: 'Neem contact op',
    messageLabel: 'Beschrijving',
    messagePlaceholder: 'Stel hier je vraag, of beschrijf je probleem.',
    nameLabel: 'Je naam',
    isNameRequired: true,
    isEmailRequired: true,
    namePlaceholder: '',
    showBranding: false,
    submitButtonLabel: 'Insturen',
    successMessageText: 'Bedankt voor je bericht!',
    useSentryUser: { name: 'name', email: 'email' },
  })
</script>
