<template></template>

<script setup lang="ts">
  import { onUnmounted, onMounted } from 'vue'

  const emit = defineEmits(['next'])

  const scrollHandler = () => {
    if (
      window.innerHeight + Math.round(window.scrollY) >=
      document.body.offsetHeight - 50
    ) {
      emit('next')
    }
  }

  onMounted(() => {
    document.addEventListener('scroll', scrollHandler)
  })

  onUnmounted(() => {
    document.removeEventListener('scroll', scrollHandler)
  })
</script>
