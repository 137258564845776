<template>
  <div>
    <label>
      <span>Groep</span>

      <select v-model="membership.groupId" required>
        <option value="">Kies een groep</option>
        <optgroup label="Groepen">
          <option v-for="group in authorizedGroups" :value="group.id">
            {{ group.name }}
          </option>
        </optgroup>
      </select>
    </label>

    <label v-if="tenant">
      <span>Rol</span>

      <select v-model="membership.roleId" required>
        <option value="">Kies een rol</option>
        <optgroup label="Rollen">
          <option v-for="role in tenant.roles as Role[]" :value="role.id">
            {{ role.title }}
          </option>
        </optgroup>
      </select>
    </label>
  </div>
</template>

<script setup lang="ts">
  import { inject } from 'vue'

  defineProps<{ membership: NewMembership }>()

  const authorizedGroups = inject<Group[]>('authorizedGroups')
  const tenant = inject<Tenant>('tenant')
</script>
