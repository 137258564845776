<template>
  <div v-if="people.length" class="space-y-2">
    <h2 class="font-bold">{{ role.title }}</h2>
    <people-list v-if="people.length" :people="people" />
    <div v-else>Geen leden voor deze rol.</div>
  </div>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue'
  import PeopleList from './PeopleList.vue'

  const emit = defineEmits(['report:count'])
  const props = defineProps<{ role: Role; groupId: string }>()
  const people = ref([])

  const loadPeople = async () => {
    const resp = await fetch(
      `/api/people?roleId=${props.role.id}&groupId=${props.groupId}`,
    )

    people.value = await resp.json()
    emit('report:count', people.value.length)
  }

  watch(() => props.groupId, loadPeople)
  loadPeople()
</script>
