<template>
  <div class="space-y-6">
    <h1 class="flex items-center gap-2 text-lg font-bold">
      <router-link :to="{ name: 'People' }">
        <arrow-left-icon class="w-5" />
      </router-link>

      <span>Nieuw lid toevoegen</span>
    </h1>

    <div class="mx-auto md:max-w-xl">
      <person-form
        :person="person"
        :is-done="isSaved"
        @submit="createPerson"
        button-title="Aanmaken"
      >
        <fieldset>
          <legend>Lidmaatschap</legend>
          <membership-form :membership="membership" />
        </fieldset>
      </person-form>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { reactive, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { useHead } from '@vueuse/head'

  import PersonForm from '../components/PersonForm.vue'
  import MembershipForm from '../components/MembershipForm.vue'
  import { ArrowLeftIcon } from '@heroicons/vue/24/outline'

  const router = useRouter()
  const isSaved = ref(false)

  const membership = reactive<NewMembership>({
    groupId: '',
    roleId: '',
    startingAt: new Date().toISOString(),
  })

  const person = reactive<NewPerson>({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: 0,
    email: '',
    phoneNumber: '',
    mobilePhoneNumber: '',
    postalCode: '',
    houseNumber: '',
    houseNumberSuffix: '',
    street: '',
    locality: '',
    country: 'NL',
    province: '',
    iban: '',
    bic: '',
    memberships: [membership],
  })

  const preloadPerson = () => {
    person.firstName = 'Sarah'
    person.lastName = 'de Vries'
    person.postalCode = '8911KD'
    person.houseNumber = '8'
    person.street = 'Tuinen'
    person.locality = 'Leeuwarden'
    person.province = 'Fryslân'
    person.country = 'NL'
  }

  if (import.meta.env.VITE_ENVIRONMENT === 'dev') {
    preloadPerson()
  }

  const createPerson = async () => {
    const method = 'POST'
    const body = JSON.stringify(person)

    const resp = await fetch('/api/people', { method, body })
    const data = await resp.json()

    if (data.success) {
      isSaved.value = true

      setTimeout(() => {
        router.push({ name: 'PersonDetails', params: { id: data.id } })
      }, 1500)
    }
  }

  useHead({ title: 'Nieuw lid' })
</script>
