<template>
  <div>
    <label>
      <span>Postcode</span>
      <input
        v-model.trim="person.postalCode"
        required
        minlength="5"
        maxlength="6"
        placeholder="0000AB"
      />
    </label>

    <div class="flex gap-1">
      <label>
        <span>Huisnummer</span>
        <input
          v-model.trim.number="person.houseNumber"
          required
          pattern="[0-9]+"
        />
      </label>

      <label>
        <span>Toevoeging</span>
        <input v-model.trim="person.houseNumberSuffix" />
      </label>
    </div>
  </div>

  <div>
    <label>
      <span>Straat</span>
      <input v-model.trim="person.street" required />
    </label>

    <label>
      <span>Woonplaats</span>
      <input v-model.trim="person.locality" required />
    </label>
  </div>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue'

  const props = defineProps<{ person: NewPerson | Person }>()
  const addressTimeout = ref(0)

  const getAddress = async () => {
    window.clearTimeout(addressTimeout.value)

    if (
      props.person.postalCode.match(/[0-9]{4}[A-z]{2}/) &&
      props.person.houseNumber
    ) {
      addressTimeout.value = window.setTimeout(doGetAddress, 500)
    }
  }

  const doGetAddress = async () => {
    const method = 'POST'

    const body = JSON.stringify({
      postalCode: props.person.postalCode,
      houseNumber: props.person.houseNumber,
      houseNumberSuffix: props.person.houseNumberSuffix,
    })

    const resp = await fetch('/api/address/complete', { body, method })

    if (resp.ok) {
      const data = await resp.json()
      Object.assign(props.person, data.result)
    }
  }

  watch(() => props.person.houseNumber, getAddress)
  watch(() => props.person.postalCode, getAddress)
</script>
