<template>
  <div class="space-y-2">
    <h2 class="font-bold">Facturen bekijken</h2>

    <div>
      <div v-if="isDone">
        <div v-if="invoices.length" class="border-t">
          <a
            :href="`/api/invoices/${invoice.id}`"
            v-for="invoice in invoices"
            :key="invoice.id"
            class="grid items-start gap-x-2 border-b py-1 hover:bg-slate-100 sm:grid-cols-4"
          >
            <span class="text-xs sm:text-right sm:text-base">{{
              invoice.date
            }}</span>
            <span class="truncate sm:order-first sm:col-span-3"
              ><strong>#{{ invoice.no }}</strong> {{ invoice.title }}</span
            >
          </a>
        </div>
        <div v-else>Er zijn nog geen facturen aangemaakt.</div>
      </div>

      <div v-else>
        <a @click="loadInvoices" class="button">Laad bestaande facturen</a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'

  const invoices = ref<Invoice[]>([])
  const isDone = ref(false)

  const loadInvoices = async () => {
    const resp = await fetch('/api/invoices')
    invoices.value = await resp.json()
    isDone.value = true
  }
</script>
