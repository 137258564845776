<template>
  <div class="my-10 flex flex-col items-center">
    <div>
      <form
        @submit.prevent="doLogin"
        class="flex flex-col items-center gap-6 rounded-xl p-4"
      >
        <figure>
          <img
            v-if="tenant?.imageData"
            :src="tenant.imageData"
            class="h-16 rounded-lg bg-white p-2 shadow-lg"
          />
          <span v-else class="leading-tight">Ledenbeheer</span>
        </figure>

        <p>
          <input
            @keydown="keyDownHandler"
            v-model.trim="email"
            pattern="^[a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-]{1,}[.]{1}[a-zA-Z0-9]{2,}$"
            type="email"
            class="rounded-md bg-slate-200 px-2 py-1 text-center"
            placeholder="E-mailadres"
            title="Voer een geldig e-mailadres in"
            :disabled="showDigits"
            autofocus
            required
          />
        </p>
        <p>
          <button class="button pr-3" :disabled="isFetching || showDigits">
            Inloggen
          </button>
        </p>
      </form>

      <form
        v-if="showDigits"
        @submit.prevent="doValidateDigits"
        class="flex flex-col items-center gap-6 rounded-xl p-4"
      >
        <p class="max-w-xs rounded-lg border px-2 py-1 text-center text-sm">
          Als je toegang hebt hebben we je een mailtje gestuurd, met een
          controlecode om in te loggen. Vul die hieronder in.
        </p>
        <p>
          <input
            v-model.trim="digits"
            autocomplete="one-time-code"
            class="rounded-md bg-slate-200 px-2 py-1 text-center"
            inputmode="numeric"
            pattern="^[0-9]{6}$"
            placeholder="Controlecode"
            ref="digitsInput"
            title="Voer de controlecode in"
          />
        </p>

        <p>
          <button class="button pr-3" :disabled="isFetching">Controleer</button>
        </p>

        <p
          v-if="digitsInvalid"
          class="max-w-xs rounded-lg border px-2 py-1 text-center text-sm"
        >
          De toegangscode lijkt niet te kloppen. Controleer je invoer.
        </p>
      </form>
    </div>

    <div
      class="bottom-2 left-2 w-24 truncate text-xs text-neutral-300 sm:fixed"
    >
      {{ version }}
    </div>
  </div>
</template>

<script setup lang="ts">
  import { v4 as uuid } from 'uuid'
  import { inject, nextTick, ref } from 'vue'

  import Cookies from 'js-cookie'

  const version = inject<string>('appVersion')

  const isFetching = ref(false)
  const showDigits = ref(false)
  const digitsInvalid = ref(false)

  const email = ref('')

  const digitsInput = ref<HTMLInputElement | null>(null)
  const digits = ref('')
  const tenant = inject<Tenant>('tenant')
  let browserToken: string | undefined

  const doValidateDigits = async () => {
    digitsInvalid.value = false

    const body = JSON.stringify({
      browserToken,
      digits: digits.value,
    })
    const method = 'POST'

    const resp = await fetch('/auth/validate', { method, body })

    if (resp.ok) {
      const data = await resp.json()
      if (data.success) {
        window.location.href = data.redirectUrl
      } else {
        digitsInvalid.value = true
      }
    } else {
      digitsInvalid.value = true
    }
  }

  const doLogin = async () => {
    isFetching.value = true
    browserToken = uuid()

    const method = 'POST'
    const body = JSON.stringify({
      email: email.value,
      browserToken,
    })

    const resp = await fetch('/auth', { method, body })
    const data = await resp.json()

    if (data.success) {
      showDigits.value = true
      nextTick(() => digitsInput.value?.focus())

      const expires = new Date(new Date().getTime() + 15 * 60 * 1000)
      const isDev = import.meta.env.DEV

      Cookies.set('loginToken', browserToken, {
        expires,
        sameSite: 'lax',
        secure: !isDev,
      })
    }

    isFetching.value = false
  }

  const keyDownHandler = (evt: KeyboardEvent) => {
    if (evt.key === ' ') {
      evt.preventDefault()
      return false
    }
  }
</script>
