<template>
  <div
    class="fixed left-0 top-0 z-10 !mt-0 size-full bg-white/90 px-4"
    @click.self="dismiss"
  >
    <form
      @submit.prevent="createGroup"
      class="border- slate-200 relative mx-auto my-12 flex w-full max-w-md flex-col gap-4 border bg-slate-100 p-4 py-3 shadow-xl"
    >
      <h2 class="font-bold">Nieuwe groep</h2>

      <label class="flex flex-col gap-2">
        <span>Groepsnaam</span>
        <input v-model="newGroup.name" class="rounded-md px-2 py-1" autofocus />
      </label>

      <label v-if="rootGroups.length > 1" class="flex flex-col gap-2">
        <span>Plaats in groep</span>
        <select
          v-model="newGroup.parentId"
          class="appearance-none rounded-md bg-white px-2 py-1"
        >
          <option v-for="group in rootGroups" :key="group.id" :value="group.id">
            {{ group.name }}
          </option>
        </select>
      </label>

      <div class="flex items-center">
        <a
          @click="dismiss"
          class="flex cursor-pointer items-center gap-1 text-sm"
        >
          <x-mark-icon class="w-4" />
          <span>Annuleren</span>
        </a>
        <button class="button ml-auto" :disabled="!isValid || isFetching">
          Aanmaken
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
  import { computed, reactive, ref } from 'vue'
  import { XMarkIcon } from '@heroicons/vue/20/solid'
  import { useRouter } from 'vue-router'

  const emit = defineEmits(['reload:user'])
  const props = defineProps<{ groups: Group[] }>()

  const rootGroups = computed(() => props.groups.filter((g) => !g.parentId))

  const newGroup = reactive({
    name: '',
    parentId: rootGroups.value[0].id,
  })

  const router = useRouter()
  const dismiss = () => {
    router.go(-1)
  }

  const isFetching = ref(false)
  const isValid = computed(
    () => newGroup.name.length > 0 && newGroup.parentId.length > 0,
  )

  const createGroup = async () => {
    isFetching.value = true

    const method = 'POST'

    const body = JSON.stringify(newGroup)
    const resp = await fetch('/api/groups', { body, method })

    isFetching.value = false

    if (resp.ok) {
      const data = await resp.json()
      const groupId = data.groupId

      emit('reload:user')

      router.push({ name: 'GroupDetails', params: { groupId } })
    }
  }
</script>
