<template>
  <arrow-path-icon v-show="showSpinner" class="w-6 animate-spin" />
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import { ArrowPathIcon } from '@heroicons/vue/24/outline'

  const showSpinner = ref(false)
  setTimeout(() => (showSpinner.value = true), 350)
</script>
