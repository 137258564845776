<template>
  <div>
    <div v-if="isAuthorized">
      <top-header @logout="doLogout" />

      <section>
        <div class="mx-auto max-w-4xl p-4 pb-24">
          <router-view
            v-slot="{ Component }"
            :user="user"
            @reload:user="loadUser"
          >
            <keep-alive :include="['People', 'Finance']">
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </div>
      </section>

      <contact-widget />
    </div>

    <login-form v-else />
  </div>
</template>

<script setup lang="ts">
  interface AuthData {
    tenant: Tenant | null
    user: Person | null
    authorizedGroups: []
  }

  import { provide, ref } from 'vue'
  import { useHead } from '@vueuse/head'
  import * as Sentry from '@sentry/vue'

  import TopHeader from './components/TopHeader.vue'
  import LoginForm from './components/LoginForm.vue'
  import ContactWidget from './components/ContactWidget.vue'

  const isAuthorized = ref(false)

  const user = ref<Person | null>(null)
  const tenant = ref<Tenant | null>(null)

  const authorizedGroups = ref([])
  const genderTitles = ['Onbekend', 'Man', 'Vrouw']

  provide('tenant', tenant)
  provide('authorizedGroups', authorizedGroups)
  provide('genderTitles', genderTitles)
  provide('user', user)
  provide('initial-path', window.location.pathname)

  const doLogout = async () => {
    await fetch('/auth', { method: 'DELETE' })
    isAuthorized.value = false
  }

  const loadUser = async () => {
    const resp = await fetch('/auth/status')
    const data: AuthData = await resp.json()

    tenant.value = data.tenant

    if (resp.status === 200) {
      isAuthorized.value = true

      authorizedGroups.value = data.authorizedGroups
      user.value = data.user

      if (user.value) {
        Sentry.setUser({
          id: user.value.id,
          name: [user.value.firstName, user.value.lastName]
            .filter((a) => a)
            .join(' '),
          email: user.value.email,
        })
      }
    } else {
      isAuthorized.value = false
    }
  }

  loadUser()

  useHead({
    titleTemplate: (title = '') => {
      const name = tenant.value?.name || 'Ledenbeheer'
      return title ? `${title} - ${name}` : name
    },

    meta: [
      {
        name: 'theme-color',
        content: () => (isAuthorized.value ? '#f1f5f9' : '#ffffff'),
      },
    ],
  })
</script>
