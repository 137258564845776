import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createHead } from '@vueuse/head'
import * as Sentry from '@sentry/vue'

import './style.css'

import App from './App.vue'
import Dashboard from './views/Dashboard.vue'
import People from './views/People.vue'
import Finance from './views/Finance.vue'
import Documents from './views/Documents.vue'
import Groups from './views/Groups.vue'
import NewGroup from './views/NewGroup.vue'
import NewPerson from './views/NewPerson.vue'
import GroupDetails from './views/GroupDetails.vue'
import PersonDetails from './views/PersonDetails.vue'

const routes = [
  { name: 'Dashboard', path: '/', component: Dashboard },
  { name: 'Finance', path: '/finance', component: Finance },
  { name: 'Documents', path: '/documents', component: Documents },
  { name: 'People', path: '/people', component: People },
  {
    name: 'Groups',
    path: '/groups',
    component: Groups,
    children: [
      { name: 'NewGroup', path: '/groups/new', component: NewGroup },
      {
        name: 'GroupDetails',
        path: '/groups/:groupId',
        component: GroupDetails,
        props: true,
      },
    ],
  },
  { name: 'NewPerson', path: '/people/new', component: NewPerson },
  {
    name: 'PersonDetails',
    path: '/people/:id',
    component: PersonDetails,
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

const head = createHead()
const app = createApp(App)
const release = __APP_VERSION__

Sentry.init({
  app,
  release,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.DEV ? 'development' : 'production',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/.+\.ledenbeheer\.eu/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

app.use(router).use(head).provide('appVersion', release).mount('#app')
