<template>
  <form @submit.prevent="doExport" class="space-y-2">
    <h2 class="font-bold">SEPA-export</h2>

    <div class="space-y-3">
      <fieldset class="pt-0">
        <div>
          <label class="col-span-full">
            <span>Omschrijving</span>
            <input v-model="variables.description" />
          </label>
        </div>
      </fieldset>

      <group-tariff-calculator @update="tariffUpdateHandler" :reset="doReset" />

      <div
        v-if="selectedGroup && !selectedGroup.allowSepaExport"
        class="space-y-2 rounded-lg border border-red-200 bg-red-50 px-3 py-2 text-xs text-red-600"
      >
        <p>
          Voor {{ selectedGroup?.name }} is de SEPA-export nog niet beschikbaar,
          omdat de benodige gegevens missen. Neem contact op met support en
          stuur de volgende gegevens mee:
        </p>

        <ol class="list-inside list-decimal space-y-1">
          <li>Incassant ID;</li>
          <li>Uw BIC en IBAN-nummer;</li>
          <li>De volledige naam bij uw bankrekening.</li>
        </ol>
      </div>

      <button v-else class="button" :disabled="variables.tariffs.length === 0">
        Exportbestand aanmaken
      </button>

      <div
        v-if="error"
        class="space-y-2 rounded-lg border px-3 py-2 text-xs text-red-800"
      >
        <p>
          <span class="block text-sm font-bold">Foutmelding</span>
          {{ error }}
        </p>

        <ul v-if="invalidRecords.length" class="list-inside list-disc">
          <li v-for="record in invalidRecords" :key="record.id">
            <router-link
              class="underline"
              :to="{ name: 'PersonDetails', params: { id: record.id } }"
              >{{ record.firstName }} {{ record.lastName }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
  import { Ref, computed, inject, nextTick, reactive, ref } from 'vue'
  import GroupTariffCalculator from '../components/GroupTariffCalculator.vue'

  const doReset = ref(false)
  const error = ref<string | null>(null)
  const invalidRecords = ref<Person[]>([])

  const authorizedGroups = inject<Ref<Group[]>>('authorizedGroups')
  const selectedGroup = computed(() => {
    if (authorizedGroups?.value && variables.groupId.length) {
      return authorizedGroups.value.find((g) => g.id === variables.groupId)
    } else {
      return null
    }
  })

  const variables = reactive({
    groupId: '',
    description: '',
    tariffs: [] as RoleTariff[],
  })

  const tariffUpdateHandler = (evt: GroupTariffs) => {
    variables.tariffs = evt.tariffs
    variables.groupId = evt.groupId
  }

  const doExport = async () => {
    const body = JSON.stringify(variables)
    const resp = await fetch('/api/sepa_export', { method: 'POST', body })
    const data = await resp.json()

    if (data.success) {
      const blob = new Blob([data.body], { type: 'text/xml' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.setAttribute('href', url)
      a.setAttribute('download', 'sepa_export.xml')
      a.click()

      doReset.value = true
      nextTick(() => (doReset.value = false))
      variables.description = ''
    } else if (data.invalidIban) {
      invalidRecords.value = data.invalidIban
      error.value =
        'Voor de volgende personen zijn ongeldige IBAN-nummers gevonden.'
    } else if (data.invalidBic) {
      invalidRecords.value = data.invalidBic
      error.value =
        'Voor de volgende personen zijn ongeldige BIC-nummers gevonden.'
    } else {
      error.value = data.message
    }
  }
</script>
