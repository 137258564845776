<template>
  <div class="space-y-6">
    <div
      v-for="post in posts"
      :key="post.id"
      class="flex max-w-md flex-col gap-2 bg-yellow-100 p-4 shadow-lg"
      :class="rotationClass()"
    >
      <h2 class="font-bold">{{ post.title }}</h2>
      <div v-html="post.body" class="space-y-2 [&_a]:underline" />

      <time class="text-sm opacity-45">{{
        formattedTimestamp(post.insertedAt)
      }}</time>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import { formattedTimestamp } from '../mixins'

  const posts = ref<Post[]>([])

  const rotationClass = () => {
    return ['rotate-1', '-rotate-1', 'rotate-0'].sort(
      () => 0.5 - Math.random(),
    )[0]
  }

  const loadPosts = async () => {
    const resp = await fetch('/api/posts')
    posts.value = await resp.json()
  }

  loadPosts()
</script>
