<template>
  <form @submit.prevent="generateInvoice" class="space-y-2">
    <h2 class="font-bold">Factuur aanmaken</h2>

    <div class="space-y-3">
      <fieldset class="pt-0">
        <div>
          <label class="col-span-full">
            <span>Omschrijving</span>
            <input v-model.trim="variables.title" />
          </label>
        </div>
      </fieldset>

      <group-tariff-calculator @update="tariffUpdateHandler" :reset="false" />

      <div class="flex">
        <button class="button" :disabled="variables.tariffs.length === 0">
          Factuur aanmaken
        </button>
        <a
          v-if="invoiceUrl"
          :href="invoiceUrl"
          class="button ml-auto bg-orange-700 hover:bg-orange-800"
          target="_blank"
          >Downloaden</a
        >
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
  import { reactive, ref } from 'vue'
  import GroupTariffCalculator from '../components/GroupTariffCalculator.vue'

  const variables = reactive({
    title: '',
    groupId: '',
    tariffs: [] as RoleTariff[],
  })

  const invoiceUrl = ref<string | null>(null)

  const tariffUpdateHandler = (evt: GroupTariffs) => {
    variables.tariffs = evt.tariffs
    variables.groupId = evt.groupId
  }

  const generateInvoice = async () => {
    const method = 'POST'
    const body = JSON.stringify(variables)

    const resp = await fetch('/api/invoices', { method, body })
    const data = await resp.json()

    variables.title = ''
    variables.tariffs = []
    variables.groupId = ''

    invoiceUrl.value = `/api/invoices/${data.id}`
  }
</script>
