<template>
  <section class="sm:col-span-5 sm:mt-1">
    <div>
      <role-people
        v-for="role in adminRoles"
        :group-id="groupId"
        :role="role"
        :key="role.id"
        @report:count="roleCount += $event"
        class="mb-3"
      />
    </div>

    <div v-if="roleCount === 0" class="mb-3">
      Voor deze groep zijn geen bestuursrollen gevonden.
    </div>

    <div>
      <router-link
        :to="{ name: 'People', query: { groupId } }"
        class="inline-flex w-full cursor-pointer items-start gap-1 text-sm underline"
      >
        <span>Bekijk alle leden bij deze groep</span>
        <arrow-up-right-icon class="mt-1 flex-shrink-0 basis-4" />
      </router-link>
    </div>
  </section>
</template>
<script setup lang="ts">
  import { ArrowUpRightIcon } from '@heroicons/vue/20/solid'
  import { computed, inject, ref, Ref, watch } from 'vue'

  import RolePeople from '../components/RolePeople.vue'

  const props = defineProps<{ groupId: string; groups: Group[] }>()
  const roleCount = ref(0)

  const emit = defineEmits(['set:groupId'])
  const tenant = inject<Ref<Tenant>>('tenant')
  const adminRoles = computed(() =>
    (tenant?.value.roles as Role[]).filter((r) => r.isAdmin),
  )

  const initGroup = () => {
    roleCount.value = 0
    emit('set:groupId', props.groupId)
  }

  watch(() => props.groupId, initGroup)

  initGroup()
</script>
