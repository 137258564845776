<template>
  <div class="space-y-2">
    <h2 class="text-lg font-bold">Documenten</h2>

    <ul class="grid max-w-lg grid-cols-3 gap-4 sm:grid-cols-4">
      <li v-for="document in documents" :key="document.id">
        <a
          :href="`/api/documents/${document.id}`"
          target="_blank"
          class="flex flex-col items-center gap-2 rounded-xl bg-slate-100 px-2 py-3 text-center hover:bg-slate-200"
        >
          <document-icon class="w-10" />
          <span class="text-xs">{{ document.name }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import { useHead } from '@vueuse/head'
  import { DocumentIcon } from '@heroicons/vue/24/solid'

  const documents = ref<GroupDocument[]>([])

  const loadDocuments = async () => {
    const resp = await fetch('/api/documents')
    documents.value = await resp.json()
  }

  loadDocuments()

  useHead({ title: 'Documenten' })
</script>
