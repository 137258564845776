<template>
  <header class="border-b bg-slate-100">
    <div
      class="mx-auto max-w-4xl px-4 py-3"
      :class="{ '!py-2': tenant?.imageData }"
    >
      <h1 class="flex items-center">
        <router-link
          :to="{ name: 'Dashboard' }"
          class="flex items-center gap-4 py-1 text-lg font-bold"
        >
          <img
            v-if="tenant?.imageData"
            :src="tenant.imageData"
            class="h-16 rounded-lg bg-white p-2 shadow-lg"
          />
          <span v-else class="leading-tight">Ledenbeheer</span>
        </router-link>

        <div
          class="relative ml-auto hidden w-24 border-r-2 border-r-slate-300 pr-3 text-xs text-slate-500 sm:block"
        >
          <div
            @click="showMenu = !showMenu"
            class="flex cursor-pointer flex-col items-end py-1"
          >
            <span>Welkom</span>
            <span class="flex items-center">
              <user-circle-icon class="w-4" />
              <span class="w-full truncate text-right font-bold">{{
                user?.firstName
              }}</span>
            </span>
          </div>

          <nav
            v-if="showMenu"
            class="absolute -right-[2px] top-10 hidden w-32 sm:block"
          >
            <div
              @click="showMenu = false"
              class="fixed bottom-0 left-0 right-0 top-0 z-10"
            ></div>

            <ul
              class="relative z-20 rounded-b-md border border-slate-300 bg-white text-sm shadow-lg"
            >
              <li>
                <a
                  @click="emit('logout')"
                  class="flex cursor-pointer items-center gap-1 px-2 py-1"
                >
                  <arrow-right-start-on-rectangle-icon class="w-3" />
                  <span>Uitloggen</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <a
          @click="showMenu = !showMenu"
          class="-m-4 ml-auto cursor-pointer p-4 sm:hidden"
        >
          <x-mark-icon v-if="showMenu" class="w-6" />
          <bars-3-icon v-else class="w-6" />
        </a>
      </h1>
    </div>

    <nav
      class="hidden sm:!block sm:bg-slate-200"
      ref="navEl"
      :class="{ '!block': showMenu }"
    >
      <div
        class="mx-auto flex max-w-4xl flex-col space-y-[2px] sm:flex-row sm:gap-5 sm:space-y-0 sm:px-4"
      >
        <router-link
          v-for="route in routes"
          :to="route.to"
          :key="route.title"
          class=""
          >{{ route.title }}</router-link
        >

        <a @click="emit('logout')" class="!text-red-900 sm:hidden">Uitloggen</a>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
  import {
    Bars3Icon,
    XMarkIcon,
    UserCircleIcon,
    ArrowRightStartOnRectangleIcon,
  } from '@heroicons/vue/20/solid'
  import { Ref, inject, nextTick, ref, watch } from 'vue'
  import { type RouteLocationNormalizedLoaded, useRouter } from 'vue-router'

  const emit = defineEmits(['logout'])

  const navEl = ref<HTMLElement | null>(null)
  const router = useRouter()
  const tenant = inject<Tenant>('tenant')
  const user = inject<Person>('user')

  const showMenu = ref(false)

  const setActiveClass = (route: RouteLocationNormalizedLoaded) => {
    navEl.value?.querySelectorAll('.link-active').forEach((link) => {
      link.classList.remove('link-active')
    })

    const [rootRoute] = route.path.match(/\/[a-z]+/) || []

    if (rootRoute) {
      const items = navEl.value?.querySelectorAll(`[href^="${rootRoute}"]`)

      nextTick(() => {
        items?.forEach((link) => {
          link.classList.add('link-active')
        })
      })
    }
  }

  watch(router.currentRoute, (route) => {
    setActiveClass(route)
    showMenu.value = false
  })

  const routes = [
    { to: { name: 'Dashboard' }, title: 'Dashboard' },
    { to: { name: 'People' }, title: 'Ledenlijst' },
    { to: { name: 'Finance' }, title: 'Financiën' },
    { to: { name: 'Documents' }, title: 'Documenten' },
  ]

  const groups = inject<Ref<Group[]>>('authorizedGroups')
  if (groups && groups.value.length > 1) {
    routes.splice(2, 0, { to: { name: 'Groups' }, title: 'Groepen' })
  }
</script>

<style scoped lang="postcss">
  header > nav a {
    @apply cursor-pointer border-transparent bg-slate-200 px-4 py-2 font-semibold text-slate-500 sm:border-y-2 sm:px-0 sm:pb-1 sm:pt-1;
  }

  nav .router-link-active,
  nav .link-active {
    @apply border-b-slate-500 bg-slate-500 text-white sm:bg-transparent sm:text-slate-800;
  }
</style>
