<template>
  <li class="space-y-1 text-slate-500">
    <router-link
      :to="{ name: 'GroupDetails', params: { groupId: group.id } }"
      class="block cursor-pointer truncate border-r-2 border-slate-100 bg-slate-100 px-2 py-1 pr-3 hover:bg-slate-200"
      :class="{
        'border-slate-500 bg-slate-200 font-semibold text-slate-800':
          group.id === selectedGroupId,
      }"
      @click="emit('set:group', group.id)"
      >{{ group.name }}</router-link
    >

    <ul v-if="children.length" class="ml-4 space-y-1">
      <nested-groups
        v-for="child in children"
        :group="child"
        :groups="groups"
        :selected-group-id="selectedGroupId"
        @set:group="emit('set:group', $event)"
      />
    </ul>
  </li>
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  const emit = defineEmits(['set:group'])
  const props = defineProps<{
    selectedGroupId?: string
    group: Group
    groups: Group[]
  }>()

  const children = computed(() =>
    props.groups.filter((g) => g.parentId === props.group.id),
  )
</script>
