<template>
  <div class="space-y-2">
    <h2 class="text-lg font-bold">Financiën</h2>

    <div class="!mb-6 max-w-sm -rotate-1 bg-yellow-100 px-4 py-3 shadow-lg">
      <p>Hier komen de financiële hulpmiddelen.</p>

      <ol class="ml-6 list-decimal">
        <li class="line-through">Facturen aanmaken</li>
        <li class="line-through">SEPA-export maken</li>
        <li>Declaraties insturen</li>
      </ol>
    </div>

    <div class="grid gap-6 sm:grid-cols-2">
      <invoices v-if="(authorizedGroups?.length || 0) > 1" />
      <sepa-export />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { inject } from 'vue'
  import { useHead } from '@vueuse/head'

  import Invoices from '../components/Invoices.vue'
  import SepaExport from '../components/SepaExport.vue'

  const authorizedGroups = inject<Group[]>('authorizedGroups')

  useHead({ title: 'Financiën' })
</script>
