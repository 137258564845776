<template>
  <toggle-box @set:collapsed="isCollapsed = $event">
    <template #title>
      <div class="grid sm:grid-cols-3 sm:items-center">
        <span class="flex items-center gap-2 sm:col-span-2">
          <span> {{ person.firstName }} {{ person.lastName }} </span>
        </span>

        <span
          class="text-sm uppercase opacity-70 transition-opacity sm:pl-0"
          :class="{ '!sm:opacity-0 hidden': !isCollapsed }"
        >
          <span v-if="person.locality">
            {{ person.locality }}
          </span>

          <span v-else class="italic text-neutral-300">Onbekend</span>
        </span>
      </div>
    </template>

    <div class="grid gap-3 sm:grid-cols-3">
      <div class="flex flex-col" :class="{ 'hidden sm:block': noContactInfo }">
        <a
          v-if="person.mobilePhoneNumber"
          :href="`tel:${person.mobilePhoneNumber}`"
          class="truncate underline"
          >{{ person.mobilePhoneNumber }}</a
        >

        <a
          v-else-if="person.phoneNumber"
          :href="`tel:${person.phoneNumber}`"
          class="truncate underline"
          >{{ person.phoneNumber }}</a
        >

        <a
          v-if="person.email"
          :href="`mailto:${person.email}`"
          class="truncate underline"
          >{{ person.email }}</a
        >
      </div>

      <div>
        {{ person.street }} {{ person.houseNumber }}{{ person.houseNumberSuffix
        }}<br />
        {{ person.postalCode }} {{ person.locality }}
      </div>

      <div class="sm:order-first">
        <router-link
          :to="{ name: 'PersonDetails', params: { id: person.id } }"
          class="underline"
        >
          Meer informatie
        </router-link>
      </div>
    </div>
  </toggle-box>
</template>

<script setup lang="ts">
  import { ref } from 'vue'

  import ToggleBox from './ToggleBox.vue'
  const props = defineProps<{ person: Person }>()

  const isCollapsed = ref(true)

  const noContactInfo = [
    props.person.phoneNumber,
    props.person.mobilePhoneNumber,
    props.person.email,
  ].every((a) => a === null || a.length === 0)
</script>
