<template>
  <toggle-box
    :collapsed="isCollapsed"
    :in-list="false"
    @set:collapsed="isCollapsed = $event"
  >
    <template #title>Nieuw lidmaatschap</template>

    <form @submit.prevent="createMembership">
      <fieldset>
        <membership-form :membership="membership" />
      </fieldset>

      <div class="flex items-center gap-1">
        <button class="button">Toevoegen</button>
        <span v-if="error" class="ml-auto text-red-700">{{ error }}</span>
      </div>
    </form>
  </toggle-box>
</template>

<script setup lang="ts">
  import { reactive, ref } from 'vue'
  import ToggleBox from './ToggleBox.vue'
  import MembershipForm from '../components/MembershipForm.vue'

  const emit = defineEmits(['reload:person'])
  const props = defineProps<{ personId: string }>()
  const isCollapsed = ref(true)
  const error = ref<string | null>(null)

  const membership = reactive<NewMembership>({
    groupId: '',
    roleId: '',
    startingAt: new Date().toISOString(),
    personId: props.personId,
  })

  const createMembership = async () => {
    const method = 'POST'
    const body = JSON.stringify(membership)

    const resp = await fetch('/api/memberships', { method, body })

    if (resp.status === 200) {
      membership.groupId = ''
      membership.roleId = ''
      isCollapsed.value = true
      emit('reload:person')
    } else {
      error.value = 'Controleer invoer'
    }
  }
</script>
