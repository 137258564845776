<template>
  <toggle-box
    tag="li"
    :collapsed="isCollapsed"
    @set:collapsed="isCollapsed = $event"
  >
    <template #title>
      <div class="flex flex-col">
        <span
          class="truncate"
          :class="{ 'text-red-700 line-through': localMembership.endingAt }"
          >{{ membership.role.title }}</span
        >
        <div class="flex gap-1 text-xs">
          <time :datetime="membership.startingAt">
            {{ formattedTimestamp(membership.startingAt) }}
          </time>

          <div v-if="localMembership.endingAt">
            -
            <time
              :datetime="localMembership.endingAt"
              class="font-bold text-red-700"
            >
              {{ formattedTimestamp(membership.endingAt) }}
            </time>
          </div>
        </div>
      </div>
    </template>

    <form @submit.prevent="saveMembership" class="p-2">
      <label class="flex flex-col">
        <span class="text-xs font-semibold">Einddatum</span>
        <div class="flex gap-1">
          <input
            type="date"
            v-model="localMembership.endingAt"
            class="w-full"
          />
          <button
            :disabled="!localMembership.endingAt"
            class="ml-auto disabled:opacity-25"
          >
            <check-circle-icon class="w-5" />
          </button>
        </div>

        <span v-if="error" class="block pt-1 text-center text-red-700">{{
          error
        }}</span>
      </label>
    </form>
  </toggle-box>
</template>

<script setup lang="ts">
  import { formattedTimestamp } from '../mixins'
  import { ref, reactive } from 'vue'
  import { CheckCircleIcon } from '@heroicons/vue/20/solid'

  import ToggleBox from './ToggleBox.vue'

  const props = defineProps<{ membership: Membership }>()
  const localMembership = reactive(props.membership)
  const isCollapsed = ref(true)
  const error = ref<string | null>(null)

  const saveMembership = async () => {
    const method = 'PUT'
    const body = JSON.stringify({ endingAt: localMembership.endingAt })

    const resp = await fetch(`/api/memberships/${props.membership.id}`, {
      method,
      body,
    })

    if (resp.status === 200) {
      isCollapsed.value = true
    } else {
      error.value = 'Controleer invoer'
    }
  }
</script>
