<template>
  <component
    :is="tag"
    class="border transition-all"
    :class="{
      'border-b-0 last:border-b': inList,
      'overflow-hidden rounded-lg': !inList,
      'border-l-white border-r-white': isCollapsed && inList,
      'mb-2 !border-b shadow-md': !isCollapsed && inList,
    }"
  >
    <div
      class="hover:bg-slate-100"
      :class="{ 'bg-slate-100': !isCollapsed || !inList }"
      @click="isCollapsed = !isCollapsed"
      @dblclick="emit('title:dblclick')"
    >
      <div
        class="flex cursor-pointer select-none items-start gap-1 py-1 pl-1 sm:pr-2"
      >
        <div class="mt-1 flex-shrink-0 basis-4 text-teal-800">
          <chevron-right-icon v-if="isCollapsed" class="w-full" />
          <chevron-down-icon v-else class="w-full" />
        </div>

        <div class="w-full">
          <slot name="title" />
        </div>
      </div>
    </div>

    <div
      v-if="!isCollapsed"
      class="col-span-full border-t border-dashed px-3 py-2 text-sm"
    >
      <slot />
    </div>
  </component>
</template>

<script setup lang="ts">
  import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'
  import { ref, watch } from 'vue'

  const props = defineProps({
    collapsed: {
      type: Boolean,
      default: () => true,
    },

    inList: {
      type: Boolean,
      default: () => true,
    },

    tag: {
      type: String,
      default: () => 'div',
    },
  })

  const emit = defineEmits(['set:collapsed', 'title:dblclick'])
  const isCollapsed = ref(props.collapsed)

  watch(
    () => props.collapsed,
    (bool) => {
      isCollapsed.value = bool
    },
  )

  watch(isCollapsed, (bool) => emit('set:collapsed', bool))
</script>
