<template>
  <div v-if="person" class="space-y-2">
    <h1 class="flex items-center gap-2 text-lg font-bold">
      <a @click="goBack" class="cursor-pointer">
        <arrow-left-icon class="w-5" />
      </a>

      <span>{{ person.firstName }} {{ person.lastName }}</span>
    </h1>

    <div class="grid gap-4 md:grid-cols-8">
      <person-form :person="person" @save="savePerson" :isDone="isSaved" />

      <aside class="md:col-span-3">
        <div class="space-y-2 rounded-lg border p-3 shadow-sm">
          <person-groups :person="person" @reload:person="loadPerson" />
        </div>
      </aside>
    </div>
  </div>

  <div v-else-if="isDone" class="space-y-2">
    <h2 class="text-lg font-bold">Onbekend lid</h2>
    <p>Dit lid konden we niet vinden.</p>
    <p>
      <router-link :to="{ name: 'People' }" class="underline"
        >Terug</router-link
      >
    </p>
  </div>

  <loading-view v-else />
</template>

<script setup lang="ts">
  import { inject, ref } from 'vue'
  import { useHead } from '@vueuse/head'

  import { ArrowLeftIcon } from '@heroicons/vue/24/solid'

  import PersonGroups from '../components/PersonGroups.vue'
  import LoadingView from '../components/LoadingView.vue'
  import PersonForm from '../components/PersonForm.vue'
  import { useRouter } from 'vue-router'

  const props = defineProps<{ id: string }>()
  const router = useRouter()

  const initialPath = inject<string>('initial-path')
  const person = ref<Person | null>(null)
  const isDone = ref(false)
  const isSaved = ref(false)

  const savePerson = async () => {
    const method = 'PUT'
    const body = JSON.stringify(person.value)

    const resp = await fetch(`/api/people/${props.id}`, { method, body })

    if (resp.status === 200) {
      isSaved.value = true
      setTimeout(() => (isSaved.value = false), 2000)
    } else {
      alert('Er ging iets mis met het opslaan.')
    }
  }

  const loadPerson = async () => {
    const resp = await fetch(`/api/people/${props.id}`)

    if (resp.status === 200) {
      person.value = await resp.json()
    }

    isDone.value = true
  }

  loadPerson()

  useHead({
    title: () =>
      person.value
        ? `${person.value.firstName} ${person.value.lastName}`
        : null,
  })

  const goBack = () => {
    if (initialPath?.match(/\/people\/.+/)) {
      router.push({ name: 'People' })
    } else {
      router.back()
    }
  }
</script>
