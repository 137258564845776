const formattedTimestamp = (str: string) => {
  const ts = new Date(str)
  const months = [
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december',
  ]

  return [ts.getDate(), months[ts.getMonth()], ts.getFullYear()].join(' ')
}

function parseCookies(str: string | null) {
  return (str || '').split(';').reduce(
    (a, e) => {
      const [k, v] = e.trim().split('=')
      a[k] = v
      return a
    },
    {} as { [key: string]: string },
  )
}

export { formattedTimestamp, parseCookies }
