<template>
  <form
    @submit.prevent="emit('save')"
    class="space-y-4 rounded-lg border p-4 pt-3 shadow-sm md:col-span-5"
  >
    <fieldset>
      <legend class="border-t-0 pt-0">Persoonlijke gegevens</legend>
      <div>
        <label>
          <span>Voornaam</span>
          <input v-model.trim="person.firstName" required />
        </label>

        <label>
          <span>Achternaam</span>
          <input v-model.trim="person.lastName" required />
        </label>
      </div>

      <div>
        <label>
          <span>Geboortedatum</span>
          <input v-model.trim="person.dateOfBirth" type="date" class="py-0" />
        </label>

        <label>
          <span>Geslacht</span>
          <select v-model="person.gender" required>
            <option
              v-for="(title, idx) of genderTitles"
              :key="`gender-${idx}`"
              :value="idx"
            >
              {{ title }}
            </option>
          </select>
        </label>
      </div>
    </fieldset>

    <fieldset>
      <legend>Contactgegevens</legend>

      <div>
        <label>
          <span>E-mailadres</span>
          <input v-model.trim="person.email" />
        </label>
      </div>

      <div>
        <label>
          <span>Telefoonnummer</span>
          <input type="tel" v-model.trim="person.phoneNumber" />
        </label>

        <label>
          <span>Mobiel nummer</span>
          <input type="tel" v-model.trim="person.mobilePhoneNumber" />
        </label>
      </div>
    </fieldset>

    <fieldset>
      <legend>Adresgegevens</legend>

      <div>
        <label>
          <span>Land</span>
          <select v-model="person.country" required>
            <option
              v-for="(title, code) in countries"
              :key="code"
              :value="code"
            >
              {{ title }}
            </option>
          </select>
        </label>

        <label v-if="person.country === 'NL'">
          <span>Provincie</span>
          <select v-model="person.province" required>
            <option
              v-for="province in provinces"
              :key="province"
              :value="province"
            >
              {{ province }}
            </option>
          </select>
        </label>
      </div>

      <address-input :person="person" />
    </fieldset>

    <fieldset>
      <legend>Bankgegevens</legend>

      <div>
        <label class="relative">
          <span class="flex items-center gap-2">
            <span :class="{ 'text-red-800': !ibanValid }">IBAN</span>
            <exclamation-triangle-icon
              v-if="!ibanValid"
              class="ml-auto w-4 text-red-800"
            />
          </span>
          <input
            v-model.trim="person.iban"
            pattern="[A-Z]{2}[0-9]{2}[A-Z]{4}[0-9]{10}"
            minlength="18"
            maxlength="18"
          />
        </label>

        <label>
          <span>BIC</span>
          <input
            v-model.trim="person.bic"
            pattern="[A-Z]{6}[A-Z0-9]{2}"
            minlength="8"
            maxlength="8"
          />
        </label>
      </div>
    </fieldset>

    <slot />

    <div class="flex items-center gap-2">
      <button class="button" :disabled="isDisabled">
        <span>{{ buttonTitle || 'Opslaan' }}</span>
      </button>

      <check-circle-icon v-if="isDone" class="w-5" />
    </div>
  </form>
</template>

<script setup lang="ts">
  import { computed, inject } from 'vue'
  import { validateIBAN } from 'sepa'

  import { CheckCircleIcon } from '@heroicons/vue/24/solid'
  import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
  import AddressInput from './AddressInput.vue'

  const emit = defineEmits(['save'])
  const props = defineProps<{
    isDone: boolean
    person: Person | NewPerson
    buttonTitle?: string
  }>()

  const ibanValid = computed(() => {
    if (props.person.iban) {
      return validateIBAN(props.person.iban)
    } else {
      return true
    }
  })

  const isDisabled = computed(() => {
    return props.isDone || !ibanValid.value
  })

  const countries = {
    NL: 'Nederland',
    D: 'Duitsland',
    B: 'Belgie',
  }

  const genderTitles = inject<string[]>('genderTitles')

  const provinces = [
    'Drenthe',
    'Flevoland',
    'Fryslân',
    'Gelderland',
    'Groningen',
    'Limburg',
    'Noord-Brabant',
    'Noord-Holland',
    'Overijssel',
    'Utrecht',
    'Zeeland',
    'Zuid-Holland',
  ]
</script>

<style lang="postcss" scoped>
  @supports (font: -apple-system-body) and (-webkit-appearance: none) {
    label input[type='date'] {
      @apply py-[2px];
    }
  }
</style>
